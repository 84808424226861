@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    @keyframes dance {
        0%, 100% { transform: rotate(-3deg); }
        50% { transform: rotate(3deg); }
    }
  
    .animate-dance {
        animation: dance 1s infinite;
    }
  }

  
@keyframes slide {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animate-slide {
    animation: slide 40s linear infinite;
  }
  